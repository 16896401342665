import Splide from '@splidejs/splide';
import Grid from '@splidejs/splide-extension-grid';

// Contains Splide instances.
const splides = [];

const splideConfiguration = {
	'cards-carousel': {
		type: 'loop',
		start: 0,
		perPage: 3,
		perMove: 1,
		grid: {
			rows: 2,
			cols: 1,
		},
		pagination: true,
		breakpoints: {
			640: {
				perPage: 1,
			},
		},
	},

	'equipments-carousel': {
		type: 'loop',
		start: 0,
		perPage: 1,
		perMove: 1,
		pagination: true,
		grid: {
			rows: 2,
			cols: 3,
		},
		breakpoints: {
			640: {
				grid: {
					rows: 2,
					cols: 2,
				},
			},
		},
	},

	'hero-carousel': {
		type: 'loop',
		start: 0,
		perPage: 1,
		perMove: 1,
		gap: '1rem',
		arrows: false,
		pagination: false,
		autoplay: true,
		interval: 5000,
		pauseOnHover: false,
		fixedWidth: '50%',
		fixedHeight: '32rem',
		breakpoints: {
			991: {
				focus: 'center',
				fixedHeight: '25rem',
			},
			767: {
				focus: 'center',
				fixedWidth: '80%',
				fixedHeight: '25rem',
			},
			575: {
				focus: '',
				fixedWidth: '100%',
				fixedHeight: '20rem',
			},
		},
	},

	'news-carousel': {
		type: 'loop',
		start: 0,
		perPage: 3,
		perMove: 1,
		pagination: true,
		breakpoints: {
			640: {
				perPage: 1,
			},
		},
	},
};

/**
 * Get Splide instance if it exists
 *
 * @param {Element} el HTML Element to check for Splide
 * @return {Splide|void} Returns the Splide instance or undefined
 */
function getSplideInstance(el) {
	return splides.filter((splide) => splide.root === el).shift();
}

/**
 * Send Ajax call to filter the Equipment carousel
 *
 * @param {string} filter The taxonomy slug used to filter equipment
 * @return {void}
 */
function getEquipment(filter) {
	const filteredEquipment = document.getElementById('filteredEquipment');
	const equipmentBlock = document.getElementsByClassName(
		'block-carousel-equipment'
	)[0];
	if (filteredEquipment === null || equipmentBlock === null) return;

	const data = {
		nonce: disCarouselData.nonce,
		action: 'dis_load_equipment',
		filter,
	};

	equipmentBlock.classList.add('loading');

	jQuery
		.ajax({
			url: disCarouselData.ajaxUrl,
			data,
			type: 'POST',
			success(response) {
				try {
					const json = JSON.parse(response);

					// Load the rest of the equipment
					let splide = getSplideInstance(filteredEquipment);
					splides.splice(splides.indexOf(splide), 1);
					if (splide !== undefined) splide.destroy();
					filteredEquipment.querySelector('.splide__list').innerHTML =
						json.equipment;
					splide = new Splide(
						document.getElementById('filteredEquipment'),
						splideConfiguration['equipments-carousel']
					).mount({
						Grid,
					});
					splides.push(splide);
				} catch (error) {
					console.warn('Problem loading equipment: ', error); // eslint-disable-line no-console
				}
			},
		})
		.always(() => {
			equipmentBlock.classList.remove('loading');
		});
}

function initializeEquipmentCarousel() {
	const filters = document.getElementById('equipmentFilter');
	if (filters === null) return;

	filters.addEventListener('click', (event) => {
		if (event.target.className === 'btn-check') {
			getEquipment(event.target.getAttribute('data-filter'));
		}
	});

	// Initial load
	getEquipment('all');
}

function initializeSplide() {
	const s = document.getElementsByClassName('splide');
	[...s].forEach((splide) => {
		const config = splide.getAttribute('data-splide-config');
		if (config === null) return;
		if (
			config === 'cards-carousel' ||
			config === 'equipments-carousel' ||
			config === 'members-carousel' ||
			config === 'news-carousel'
		) {
			splides.push(
				new Splide(splide, splideConfiguration[config]).mount({
					Grid,
				})
			);
		} else {
			splides.push(
				new Splide(splide, splideConfiguration[config]).mount()
			);
		}
	});
}

function positionArrows() {
	const s = document.getElementsByClassName('splide');
	if (!s) {
		return;
	}
	[...s].forEach((splide) => {
		const pagination = splide.querySelector('.splide__pagination');
		const arrowLeft = splide.querySelector('.splide__arrow--prev');

		if (pagination.clientWidth === 0) {
			setTimeout(() => {
				positionArrows();
			}, 500);
		}

		let arrowMargin = pagination.clientWidth + 16;
		arrowMargin = Math.abs(arrowMargin / 16);

		arrowLeft.style.marginRight = `${arrowMargin}rem`;
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initializeSplide();
	initializeEquipmentCarousel();
	positionArrows();
});
